import axios from "axios";
import { notifyError } from "../assets/elements/Toaster";
import useAuth from "../hooks/useAuth";
import useConfig from "../hooks/useConfig";
import * as Storage from "../store/LocalStorage";
import { useNavigate } from "react-router-dom";

export default function FetchServices() {
  const config = useConfig();
  const { admin } = useAuth();
  const navigate = useNavigate();

  // console.log(admin)

  const isNotAuthorized = (status) => {
    if (status === 401) {
      notifyError("Token expired! please login again");
    //  navigate("/login");
    //  Storage.remove(config.sessionKey);
    //  window.location.reload();
    }
  };


  const AxiosServices = {
    AxiosService: function (
      API,
      payload,
      authNeeded = true,
      type = "POST",
      fileFlag,
      blob
    ) {
      let authData = {};
      if (authNeeded) {
        authData = {
          token: `Bearer ${admin?.token}`,
        };
        // console.log(authData?.token)
      }
      let header = {
        "content-type": "application/json",
        Authorization: authData.token,
      };
      if (fileFlag)
        header = {
          "Content-Type": "multipart/form-data",
          Authorization: authData.token,
        };

      return axios({
        method: type,
        url: API,
        headers: header,
        params:
          type === "GET" || type === "DELETE"
            ? {
                ...payload,
              }
            : {},
        data: payload,
        ...(blob ? { responseType: "blob" } : {}),
      })
        .then(function (response) {
          // console.log(payload);
          var resData = response.data;
          let headers = response.headers;
          let totalCount =
            headers["x-coreplatform-total-records"] &&
            headers["x-coreplatform-total-records"];
          let publicId = headers["public-id"] && headers["public-id"];
          let fileName = headers["file-name"] && headers["file-name"];
          let invoiceNo = headers["invoice_no"] && headers["invoice_no"];
          let token = headers["token"] && headers["token"];
          if (!resData) {
            return {
              status: false,
              success: true,
              data: {},
              ...(totalCount ? { totalCount } : {}),
              ...(publicId ? { publicId } : {}),
              ...(fileName ? { fileName } : {}),
              ...(invoiceNo ? { invoiceNo } : {}),
              ...(token ? { token } : {}),
            };
          }
          const apiResponse = resData;
          return {
            status: true,
            data: apiResponse,
            success: true,
            ...(totalCount ? { totalCount } : {}),
            ...(publicId ? { publicId } : {}),
            ...(fileName ? { fileName } : {}),
            ...(invoiceNo ? { invoiceNo } : {}),
            ...(token ? { token } : {}),
          };
        })
        .catch(function (res) {
          let data = res?.response || {};
          let status = data.status;
          isNotAuthorized(status);
          console.log(payload);
          return {
            status: false,
            success: false,
            message: res?.message,
            ...res?.response?.data,
          };
        });
    },
  };
  return AxiosServices;
}
