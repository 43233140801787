import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";
const LazyComponent = lazy(() => import("./CashPayments"));

export default function CashPayments() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
