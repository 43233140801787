const apiendpoint = "https://crashassistprod.claimtec.co.za";

let apiService = {
  adminSignin: `${apiendpoint}/api/auth/signin`,
  getAllCaseHistory: `${apiendpoint}/api/dreamtec-case-history/getAll`,
  getCaseById: `${apiendpoint}/api/dreamtec-case-history/get`,
  getServiceByCaseId: `${apiendpoint}/api/service-history/get`,
  updateService: `${apiendpoint}/api/service-history/updatecalc`,
  cancelService: `${apiendpoint}/api/user/service/cancel`,

  getDreamCaseHistoryByGetCase: `${apiendpoint}/api/dreamtec-case-history/getcase`,

  getCaseByReference: `${apiendpoint}/api/dreamtec-case-history/getByCaseReference`,

  updateCaseVisitStatus: `${apiendpoint}/api/dreamtec-case-history/updateVisitStatus`,
  updateServiceVisitStatus: `${apiendpoint}/api/service-history/updateVisitStatus`,

  getPaidNotificationStatus: `${apiendpoint}/notifications/getpaidnotifications?readStatus=false`,
  updatePaidNotificationStatus: `${apiendpoint}/notifications/updateReadStatus`,

  createAgent: `${apiendpoint}/api/user/create`,
  getAllAgents: `${apiendpoint}/api/user/all`,
  deleteAgent: `${apiendpoint}/api/user`,

  getAllRates: `${apiendpoint}/api/rates`,

  //templates
  getAllTemplates: `${apiendpoint}/api/whatsapp/templates`,
  createTemplate: `${apiendpoint}/api/whatsapp/template`,

  sendMessage: `${apiendpoint}/api/whatsapp/message`,
  createPayment: `${apiendpoint}/api/create-payment`,

  //customers
  getAllUsers: `${apiendpoint}/api/user/all`,
};

export default apiService;
